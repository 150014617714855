import React from "react";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export default function TrustSection(props) {
    const {
      trustsection
    } = props;

    return(
      <>
        <div className="trustsection">
          <div className="trustsection__bgimg">
            <PreviewCompatibleImage imageInfo={trustsection.bgimage}/>
          </div>
          <section className="section pt-0 pb-0">
            <div className="container">
              <div className="section pt-0 pb-0">
                <div className="columns is-gapless">

                  <div className="column is-half">
                    <div className="trustsection__copy">
                      <h1 className="trustsection__title">{trustsection.trustheadline}</h1>
                      <div className="trustsection__copy_main">
                      {trustsection.trustfactors.map((item, indx) => (
                        <p style={{marginBottom: "2rem", maxWidth: "400px"}} key={indx}>{item.tftext}</p>
                      ))}
                      <Link to="/porfolio-items/about-us/" className="btn">
                        Find Out More
                      </Link>
                      </div>
                    </div>
                  </div>

                  <div className="column is-half p-0">
                    <div className="trustsection__imgs">
                      <div className="trustsection__img trustsection__sideimg">
                        <PreviewCompatibleImage imageInfo={trustsection.trustimage1}/>
                      </div>
                      <div className="trustsection__img trustsection__sideimg trustsection__sideimg--last">
                        <PreviewCompatibleImage imageInfo={trustsection.trustimage2}/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
}
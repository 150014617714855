import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

export default function FullWidthImage(props) {
  const {
    height = 650,
    img,
    headerlinks,
    title,
    imgPosition = "top left",
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0 wb-hero"
        style={{
          // display: "grid",
          // alignItems: "end",
          display: "block",
          position: "relative",
        }}
      >
        {img?.url ? (
          <img
            src={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              // gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // height: height,
              // width: "100%",
            }}
            // This is a presentational image, so the alt should be an empty string
            alt=""
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              // gridArea: "1/1",
              // // You can set a maximum height for the image, if you wish.
              // maxHeight: height,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            // aspectratio={4 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif"]}
          />
        )}
        {(title) && (
          <div
            className="wb-hero__content"
            style={{
              // // By using the same grid area for both, they are stacked on top of each other
              // gridArea: "1/1",
              // position: "relative",
              // // This centers the other elements inside the hero component
              // placeItems: "center",
              // display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            <div className="container">
              <div className="section" style={{paddingBottom: "1rem"}}>
                <div className="columns is-gapless">
                  <div className="column is-half">
                    {title && (
                      <p className="hero-headline">
                        {title}
                      </p>
                    )}
                  </div>
                  <div className="column is-half" style={{alignSelf: "flex-end"}}>
                    <div className="btn-group">
                      {headerlinks.map((item, index) => (
                          <a 
                            key={item.text} href={item.link} 
                            className={(index == 0 ? "btn-red" : "btn" ) + " button_" + (headerlinks.length - index)}
                            style={{zIndex: (headerlinks.length - index)}}
                          >
                            {item.text}
                          </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" style={{paddingBottom: "0", paddingTop: "0"}}>
                <div className="columns is-gapless">
                  <div className="column is-half pt-5 pl-5 pr-5 pb-0 wb-hero__intro">
                    <div className="content px-5 pt-5 pb-0">
                      <div className="tile">
                        <h1>#1 Roofing in McKinney</h1>
                      </div>
                      <h4>Experienced Dallas Roofing Professionals Serving DFW, Austin, &amp; San Antonio</h4>
                    </div>
                  </div>
                  <div className="column is-half"  style={{padding: "0"}}>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  headerlinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

import { Link } from "gatsby";
import React from "react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export default function TestimonialSection(props) {
    const {
        testimonialsection
    } = props;

    return (
        <>
            <div className="testimonialsection__outer section pt-0 pb-0">
                <div className="container">
                    <div className="sectiona">
                        <div className="testimonialsection__wrapper">
                            <div className="testimonialsection columns is-gapless">
                                <div className="column is-half">
                                    <div className="testimonialsection__quotes">
                                        <div className="testimonialsection__quotes_bg">
                                            <PreviewCompatibleImage imageInfo={testimonialsection.bgimage} />
                                        </div>
                                        <div className="testimonialsection__quotes_wrapper">
                                            <div className="testimonialsection__quote is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        {testimonialsection.testimonial1.copy}
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - {testimonialsection.testimonial1.personname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="testimonialsection__quote testimonialsection__quote--offset is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        {testimonialsection.testimonial2.copy}
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - {testimonialsection.testimonial2.personname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="testimonialsection__quote is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        {testimonialsection.testimonial3.copy}
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - {testimonialsection.testimonial3.personname}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="testimonialsection__copy px-6">
                                        <div className="testimonialsection__copy_inner">
                                            <h2>{testimonialsection.title1}</h2>
                                            <p>{testimonialsection.copy1}</p>
                                            <h4>{testimonialsection.title2}</h4>
                                            <p>{testimonialsection.copy2}</p>
                                            <Link className="btn-red" to="/reviews">Read All Reviews</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";
import OurServices from "../components/OurServices";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";
import TrustSection from "../components/TrustSection";
import TestimonialSection from "../components/TestimonialSection";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  ourservices,
  headerlinks,
  maintitle,
  mainpitch,
  description,
  intro,
  trustsection,
  testimonialsection,
  contentComponent,
  content
}) => {
  const HomePageContent = contentComponent || Content;
  const heroImage = getImage(image) || image;
  return (
    <div className="main-page-content">
      <FullWidthImage img={heroImage} title={title} headerlinks={headerlinks} />
      
      <section className="section pt-0 pb-0">
        <div className="container">
          <div className="section pt-0 pb-0">
            <div className="columns is-gapless">
              <div className="column is-half pl-5 pr-5 pt-3">
              
                <div className="content p-5">
                  <div className="tile">
                    <HomePageContent className="content main-content" content={content} />
                  </div>
                </div>
              </div>
              <div className="column is-half romance-aside p-0">

                <div className="romance-aside__wrapper">
                
                  {intro.blurbs.map((item) => (
                    <div key={item.text} className="romance-aside__unit">
                      <PreviewCompatibleImage imageInfo={item.image} />
                      <div className="romance-aside__content">
                        <h4>{item.text}</h4>
                        <a href={item.link.url} className="btn">{item.link.text}</a>
                      </div>
                    </div>
                  ))}

                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurServices ourservices={ourservices} />
      <TestimonialSection testimonialsection={testimonialsection} />
      <TrustSection trustsection={trustsection} />

    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  headerlinks: PropTypes.array,
  ourservices: PropTypes.object,
  maintitle: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  trustsection: PropTypes.object,
  testimonialsection: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <IndexPageTemplate
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        headerlinks={post.frontmatter.headerlinks}
        ourservices={post.frontmatter.ourservices}
        maintitle={post.frontmatter.maintitle}
        description={post.frontmatter.description}
        intro={post.frontmatter.intro}
        trustsection={post.frontmatter.trustsection}
        testimonialsection={post.frontmatter.testimonialsection}
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
    html
    frontmatter {
      title
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      headerlinks {
        text
        link
      }
      ourservices{
        bgimage{
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        link{
          text
          url
        }
        callstoaction{
          alt
          heading
          url
          image{
            childImageSharp {
              gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
            }
          }
        }
      }
      maintitle
      description
      intro {
        blurbs {
          image {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
            }
          }
          text
          link{
            text
            url
          }
        }
      }
      testimonialsection {
        title1
        title2
        copy1
        copy2
        testimonial1 {
          copy
          personname
        }
        testimonial2 {
          copy
          personname
        }
        testimonial3 {
          copy
          personname
        }
        bgimage {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 85, layout: CONSTRAINED)
          }
        }
      }
      trustsection {
        trustfactors {
          tftext
        }
        bgimage{
          childImageSharp {
            gatsbyImageData(width: 1400, quality: 85, layout: CONSTRAINED)
          }
        }
        trustheadline
        trustimage1 {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
          }
        }
        trustimage2 {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`;
